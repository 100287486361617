import React from "react"
import SEO from "../components/utils/SEO"
import Layout from "../components/layout/Layout"
import LayoutWithImage from "../components/layout/LayoutWithImage"

const GeschichtePage = () => (
  <Layout>
    <SEO title="Geschichte" />

    <div className="pt-5 pb-5 header">
      <h1>Geschichte</h1>
      <h2>Ausstellung &laquo;blickwechsel&raquo; zum 50. Jubiläum der IV 2010.</h2>
    </div>

    <LayoutWithImage>
      <p>Im Mai 2010 wurde in der Spörry Fabrik die Ausstellung &laquo;blickwechsel&raquo; gezeigt.</p>
      <p>Die Ausstellung wurde zum 50 Jahr Jubiläum der Invaliden-Versicherung (IV) Liechtenstein organisiert.</p>
      <p>Regierungs- und Nichtregierungs- Organisationen aus dem Bereich Behinderung und Sozialhilfe haben die Ausstellung gestaltet.</p>
    </LayoutWithImage>
  </Layout>
)

export default GeschichtePage
